import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Nav from "./components/nav/Nav";
import Footer from "./components/footer/Footer";
import Single from "./pages/single/Single";
import About from "./pages/about/About";
import Clients from "./pages/clients/Clients";
import Projects from "./pages/projects/Projects";
import License from "./pages/licence/License";
import Team from "./pages/team/Team";
import ScrollToTop from "./components/scroll top/ScrollTop";
import Twitter from "./components/button/Twitter";
import Services from "./pages/services/Services";
import Contact from "./pages/contact/Contact";
import Testimonials from "./pages/testimonials/Testimonials";
import Commercial from "./pages/service-pages/commercial/Commercial";
import Residential from "./pages/service-pages/residential/Residential";
import Interior from "./pages/service-pages/interior/Interior";
import Hospitality from "./pages/service-pages/hospitality/Hospitality";
import Restaurant from "./pages/service-pages/restaurant/Restaurant";
import Office from "./pages/service-pages/office/Office";
import Beauty from "./pages/service-pages/beauty/Beauty";
import Retail from "./pages/service-pages/retail/Retail";
import Woodwork from "./pages/service-pages/wood-work/Woodwork";
import Medical from "./pages/service-pages/medical/Medical";
import Authority from "./pages/service-pages/authority/Authority";
import Landscape from "./pages/service-pages/landscape/Landscape";

import { useEffect, useState } from "react";
import Loader from "./components/loader/Loader";
import ProjectInterior from "./pages/project pages/ProjectInterior";
import BathroomInterior from "./pages/project pages/BathroomInterior";
import RetailInterior from "./pages/project pages/RetailInterior";
import FlowerInterior from "./pages/project pages/FlowerInterior";
import FullhouseInterior from "./pages/project pages/FullhouseInterior";
import OfficeInterior from "./pages/project pages/OfficeInterior";
import WoodWorkInterior from "./pages/project pages/WoodWorkInterior";
import BeautyInterior from "./pages/project pages/BeautyInterior";
import ErrorPage from "./pages/404/404";
import { Navigate } from "react-router-dom";

function App() {
  const [timeOut, setTimeOut] = useState(true);
  // Set Time out
  useEffect(() => {
    const timer = setTimeout(() => setTimeOut(false), 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {timeOut ? (
        <Loader />
      ) : (
        <div className="App">
          <Twitter />
          <Nav />

          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects/:id" element={<Single />} />
              <Route path="/about" element={<About />} />
              <Route path="/clients" element={<Clients />} />
              {/* <Route path="/projects" element={<Projects />} /> */}
              <Route path="/licence" element={<License />} />
              <Route path="/our-team" element={<Team />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/testimonials" element={<Testimonials />} />

              {/* services page */}

              {/* <Route path="/services/commercial" element={<Commercial />} /> */}
              {/* <Route path="/services/residential" element={<Residential />} /> */}
              {/* <Route path="/services/interior" element={<Interior />} /> */}
              {/* <Route path="/services/hospitality" element={<Hospitality />} /> */}
              {/* <Route path="/services/restaurant" element={<Restaurant />} /> */}
              {/* <Route path="/services/office" element={<Office />} /> */}
              {/* <Route path="/services/beauty" element={<Beauty />} />
              <Route path="/services/retail" element={<Retail />} />
              <Route path="/services/woodwork" element={<Woodwork />} />
              <Route path="/services/medical" element={<Medical />} />
              <Route path="/services/authority" element={<Authority />} />
              <Route path="/services/landscape" element={<Landscape />} /> */}

              {/* project page */}

              <Route
                path="/projects/fullhouse"
                element={<FullhouseInterior />}
              />

              <Route path="/projects/office" element={<OfficeInterior />} />

              <Route path="/projects/beauty" element={<BeautyInterior />} />
              <Route path="*" element={<ErrorPage />} />

              {/* component redirects */}

              {/* projects */}

              <Route
                path="/our-projects/woodwork-interior-designs"
                element={<WoodWorkInterior />}
              />

              <Route
                path="/our-projects/retail-fitout-interior"
                element={<RetailInterior />}
              />

              <Route
                path="/our-projects/flower-design-interior"
                element={<FlowerInterior />}
              />

              <Route
                path="/our-projects/bathroom-interior"
                element={<BathroomInterior />}
              />
              <Route
                path="/our-projects/interior-design"
                element={<ProjectInterior />}
              />
              <Route
                path="/our-projects/medical-clinic-interior-designs"
                element={<Single />}
              />

              {/* services */}
              <Route path="/our-services" element={<Services />} />
              <Route
                path="/our-services/commercial-design-interior"
                element={<Commercial />}
              />

              <Route
                path="/our-services/interior-design-services-company"
                element={<Interior />}
              />

              <Route
                path="/our-services/residential-interior-designers-dubai"
                element={<Residential />}
              />

              <Route
                path="/our-services/best-hospitality-design-firms-dubai"
                element={<Hospitality />}
              />

              <Route
                path="/our-services/restaurant-interior-design"
                element={<Restaurant />}
              />

              <Route
                path="/our-services/office-interior-design"
                element={<Office />}
              />

              <Route
                path="/our-services/salon-interior-design"
                element={<Beauty />}
              />
              <Route path="/our-services/retail-fit-out" element={<Retail />} />
              <Route
                path="/our-services/joinery-works"
                element={<Woodwork />}
              />
              <Route
                path="/our-services/medical-clinic-design-dubai"
                element={<Medical />}
              />
              <Route
                path="/our-services/authority-approval"
                element={<Authority />}
              />
              <Route
                path="/our-services/landscape-design-dubai"
                element={<Landscape />}
              />
              <Route path="/our-projects" element={<Projects />} />

              {/* redirects */}

              <Route
                path="/projects"
                element={<Navigate to="/our-projects" replace />}
              />

              <Route
                path="/services"
                element={<Navigate to="/our-services" replace />}
              />

              <Route
                path="/services/commercial"
                element={
                  <Navigate
                    to="/our-services/commercial-design-interior"
                    replace
                  />
                }
              />

              <Route
                path="/services/residential"
                element={
                  <Navigate
                    to="/our-services/residential-interior-designers-dubai"
                    replace
                  />
                }
              />

              <Route
                path="/services/interior"
                element={
                  <Navigate
                    to="/our-services/residential-interior-designers-dubai"
                    replace
                  />
                }
              />

              <Route
                path="/services/hospitality"
                element={
                  <Navigate
                    to="/our-services/best-hospitality-design-firms-dubai"
                    replace
                  />
                }
              />

              <Route
                path="/services/restaurant"
                element={
                  <Navigate
                    to="/our-services/restaurant-interior-design"
                    replace
                  />
                }
              />

              <Route
                path="/services/office"
                element={
                  <Navigate to="/our-services/office-interior-design" replace />
                }
              />

              <Route
                path="/services/beauty"
                element={
                  <Navigate to="/our-services/salon-interior-design" replace />
                }
              />
              <Route
                path="/services/retail"
                element={<Navigate to="/our-services/retail-fit-out" replace />}
              />
              <Route
                path="/services/woodwork"
                element={<Navigate to="/our-services/joinery-works" replace />}
              />
              <Route
                path="/services/medical"
                element={
                  <Navigate
                    to="/our-services/medical-clinic-design-dubai"
                    replace
                  />
                }
              />
              <Route
                path="/services/authority"
                element={
                  <Navigate to="/our-services/authority-approval" replace />
                }
              />

              {/* project redirects */}

              <Route
                path="/projects/interior"
                element={
                  <Navigate to="/our-projects/interior-design" replace />
                }
              />
              <Route
                path="/projects/bathroom"
                element={
                  <Navigate to="/our-projects/bathroom-interior" replace />
                }
              />
              <Route
                path="/projects/flower"
                element={
                  <Navigate to="/our-projects/flower-design-interior" replace />
                }
              />
              <Route
                path="/projects/retail"
                element={
                  <Navigate to="/our-projects/retail-fitout-interior" replace />
                }
              />
              <Route
                path="/projects/woodwork"
                element={
                  <Navigate
                    to="/our-projects/woodwork-interior-designs"
                    replace
                  />
                }
              />
              <Route
                path="/projects/medicals"
                element={
                  <Navigate
                    to="/our-projects/medical-clinic-interior-designs"
                    replace
                  />
                }
              />
            </Routes>
          </ScrollToTop>

          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
