import React from "react";
import ReactTypingEffect from "react-typing-effect";
import { LicenseImg } from "../../imports/Images";
import "./License.css";

function License() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["License"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };
  return (
    <>
      <div className="license__banner">
        <div className="license__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="license">
        <img src={LicenseImg} alt="" />
      </div>
    </>
  );
}

export default License;
