import React from "react";
import { Link } from "react-router-dom";
import "./404.css";

const ErrorPage = () => {
  return (
    <div className="error-container">
      <h1 className="error-code">404</h1>
      <h2 className="error-message">Oops! Page Not Found</h2>
      <p className="error-description">
        The page you're looking for does not exist. It might have been moved or
        deleted.
      </p>
      <Link to="/" className="back-home-button">
        Back to Home
      </Link>
    </div>
  );
};

export default ErrorPage;
