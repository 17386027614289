import React from "react";
import { Whatsapp } from "../../imports/Images";
import "./Twitter.css";

function Twitter() {
  return (
    <div className="twitter">
      <a
        href="https://api.whatsapp.com/send/?phone=971551574148&text&type=phone_number&app_absent=0"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Whatsapp} alt="" />
      </a>
    </div>
  );
}

export default Twitter;
