import React, { useState } from "react";
import "./Nav.css";
import { Logo } from "../../imports/Images";
import {
  ArrowDropDownIcon,
  CallIcon,
  CloseIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  MailIcon,
  MenuIcon,
  TwitterIcon,
} from "../../imports/Icons";
import Button from "../button/Button";

function Nav() {
  const [aboutNav, setAboutNav] = useState(false);
  const [aboutNavMobile, setAboutNavMobile] = useState(false);

  const [aboutServicesMobile, setAboutServicesMobile] = useState(false);

  const [aboutClientsMobile, setAboutClientsMobile] = useState(false);

  const [aboutServices, setAboutServices] = useState(false);
  const [aboutClients, setAboutClients] = useState(false);
  const [toggle, setToggle] = useState(false);

  const MobileView = () => {
    return (
      <>
        <div className="nav__mobile">
          <div className="nav__logo">
            <a href="/">
              <img src={Logo} alt="" />
            </a>
          </div>

          <div className="nav__menu">
            {toggle ? (
              <p onClick={() => setToggle(false)}>
                <CloseIcon />
              </p>
            ) : (
              <p onClick={() => setToggle(true)}>
                <MenuIcon />
              </p>
            )}
          </div>
        </div>

        {toggle ? (
          <div className="toggle__menu">
            <ul>
              <li>
                <a href="/">home</a>
              </li>

              <li
                id="about__mobile"
                onClick={() => setAboutNavMobile(!aboutNavMobile)}
              >
                <a href="/about">about us </a>
                <span>
                  <ArrowDropDownIcon />
                </span>
              </li>
              {aboutNavMobile ? (
                <div className="about__nav__mobile">
                  <ul>
                    <li>
                      <a href="/our-team">our team</a>
                    </li>

                    <li>
                      <a href="/licence">liscence and certificates</a>
                    </li>
                  </ul>
                </div>
              ) : null}

              <li
                id="about__services"
                onClick={() => setAboutServicesMobile(!aboutServicesMobile)}
              >
                <a href="/services">services </a>
                <span>
                  <ArrowDropDownIcon />
                </span>
              </li>

              {aboutServicesMobile ? (
                <div className="about__services__mobile">
                  <ul>
                    <li>
                      <a href="/our-services/commercial-design-interior">
                        commercial
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/interior-design-services-company">
                        interior design
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/residential-interior-designers-dubai">
                        residential / villa{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/best-hospitality-design-firms-dubai">
                        hospitality{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/restaurant-interior-design">
                        restaurant{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/office-interior-design">
                        office fit out{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/salon-interior-design">
                        beauty salon & spa{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/retail-fit-out">retail fit out </a>
                    </li>

                    <li>
                      <a href="/our-services/joinery-works">
                        woodwork / joiner{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/medical-clinic-design-dubai">
                        medical centre & clinics{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/authority-approval">
                        authority approvals{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/landscape-design-dubai">
                        landscape{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              ) : null}

              <li>
                <a href="/our-projects">projects</a>
              </li>

              <li
                id="about__clients"
                onClick={() => setAboutClientsMobile(!aboutClientsMobile)}
              >
                <a href="/clients">clients </a>
                <span>
                  <ArrowDropDownIcon />
                </span>
              </li>

              {aboutClientsMobile ? (
                <div className="about__clients__mobile">
                  <ul>
                    <li>
                      <a href="/testimonials">testimonials</a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </ul>

            <div className="nav__contact__now__mobile">
              <a href="/contact">
                <Button
                  className={"home__contact__btn"}
                  text={"contact now"}
                  color={"var(--gold)"}
                />
              </a>
            </div>

            <div className="nav__contact">
              <div className="nav__contact__icons">
                <p>
                  <CallIcon />

                  <a href="tel:+971551574148">
                    <span>+971551574148</span>
                  </a>
                </p>

                <p>
                  <MailIcon />

                  <a href="mailto:info.crystalhome512@gmail.com">
                    <span>info.crystalhome512@gmail.com</span>
                  </a>
                </p>
              </div>

              <div className="nav__contact__icons__others">
                <a
                  href="https://www.facebook.com/crystal.home.5477"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>

                <a
                  href="https://www.instagram.com/info.crystalhome512/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>

                <a
                  href="https://www.linkedin.com/in/crystal-home-technical-services-718542258?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B73uOwchFTfyCXTHdMO5Y9Q%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>

                <a
                  href="https://twitter.com/CrystalHomeTech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const PcView = () => {
    return (
      <div className="nav desktop">
        <div className="nav__logo">
          <a href="/">
            <img src={Logo} alt="" />
          </a>
        </div>

        <div className="nav__links">
          <ul>
            <li>
              <a href="/">home</a>
            </li>

            <li
              id="about"
              onMouseOver={() => setAboutNav(true)}
              onMouseLeave={() => setAboutNav(false)}
            >
              <a href="/about">about us </a>
              <span>
                <ArrowDropDownIcon />
              </span>

              {aboutNav ? (
                <div className="about__nav">
                  <ul>
                    <li>
                      <a href="/our-team">our team</a>
                    </li>

                    <li>
                      <a href="/licence">liscence and certificates</a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </li>

            <li
              id="services"
              onMouseOver={() => setAboutServices(true)}
              onMouseLeave={() => setAboutServices(false)}
            >
              <a href="/services">services</a>
              <span>
                <ArrowDropDownIcon />
              </span>

              {aboutServices ? (
                <div className="about__services">
                  <ul>
                    <li>
                      <a href="/our-services/commercial-design-interior">
                        commercial
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/interior-design-services-company">
                        interior design
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/residential-interior-designers-dubai">
                        residential / villa{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/best-hospitality-design-firms-dubai">
                        hospitality{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/restaurant-interior-design">
                        restaurant{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/office-interior-design">
                        office fit out{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/salon-interior-design">
                        beauty salon & spa{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/retail-fit-out">retail fit out </a>
                    </li>

                    <li>
                      <a href="/our-services/joinery-works">
                        woodwork / joiner{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/medical-clinic-design-dubai">
                        medical centre & clinics{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/authority-approval">
                        authority approvals{" "}
                      </a>
                    </li>

                    <li>
                      <a href="/our-services/landscape-design-dubai">
                        landscape{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </li>

            <li>
              <a href="/our-projects">projects</a>
            </li>

            <li
              id="clients"
              onMouseOver={() => setAboutClients(true)}
              onMouseLeave={() => setAboutClients(false)}
            >
              <a href="/clients">our clients</a>
              <span>
                <ArrowDropDownIcon />
              </span>

              {aboutClients ? (
                <div className="about__clients">
                  <ul>
                    <li>
                      <a href="/testimonials">testimonials</a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </li>
          </ul>
        </div>

        <div className="nav__contact__now">
          <a href="/contact">
            <Button
              className={"home__contact__btn"}
              text={"contact now"}
              color={"var(--gold)"}
            />
          </a>
        </div>

        <div className="nav__contact">
          <div className="nav__contact__icons">
            <p>
              <CallIcon />

              <a href="tel:+971551574148">
                <span>+971551574148</span>
              </a>
            </p>

            <p>
              <MailIcon />

              <a href="mailto:info.crystalhome512@gmail.com">
                <span>info.crystalhome512@gmail.com</span>
              </a>
            </p>
          </div>

          <div className="nav__contact__icons__others">
            <a
              href="https://www.facebook.com/crystal.home.5477"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>

            <a
              href="https://www.instagram.com/info.crystalhome512/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>

            <a
              href="https://www.linkedin.com/in/crystal-home-technical-services-718542258?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B73uOwchFTfyCXTHdMO5Y9Q%3D%3D"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>

            <a
              href="https://twitter.com/CrystalHomeTech"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <PcView />
      <MobileView />
    </>
  );
}

export default Nav;
