import React from "react";
import { StarIcon } from "../../imports/Icons";
import { Google, Project1 } from "../../imports/Images";
import "./Review.css";

function Review({ name, date, desc }) {
  return (
    <div className="review">
      <div className="review__header">
        <p className="img">
          <img src={Project1} alt="" />
          <p className="name">
            <h4>{name}</h4>

            {/* <p>{date}</p> */}
          </p>
        </p>

        <p className="google">
          <img src={Google} alt="" />
        </p>
      </div>

      <div className="review__rate">
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </div>

      <div className="review__comment">{desc}</div>
    </div>
  );
}

export default Review;
