import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import { Logo } from "../../../imports/Images";
import "./Commercial.css";
import Head from "../../../components/meta/Head";
export const pics = [
  {
    url: "https://images.unsplash.com/photo-1600607686527-6fb886090705?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    alt: "commercial interior design",
  },

  {
    url: "https://images.unsplash.com/photo-1615873968403-89e068629265?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    alt: "commercial interior design company in dubai",
  },
  {
    url: "https://images.unsplash.com/photo-1616486338812-3dadae4b4ace?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
    alt: "best interior design dubai",
  },

  {
    url: "https://images.unsplash.com/photo-1594873604892-b599f847e859?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    alt: "interior fitouts",
  },

  {
    url: "https://images.unsplash.com/photo-1600054648630-e10e710825f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
    alt: "commercial interior decorator",
  },

  {
    url: "https://images.unsplash.com/photo-1628744876497-eb30460be9f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    alt: "interior design for commercial",
  },

  {
    url: "https://images.unsplash.com/photo-1615875605825-5eb9bb5d52ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjJ8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
    alt: "interior design near me",
  },

  {
    url: "https://images.unsplash.com/photo-1597218868981-1b68e15f0065?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
    alt: "interior design companies near me",
  },

  {
    url: "https://images.unsplash.com/photo-1627903366077-245881c54748?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
    alt: "interior design company",
  },

  {
    url: "https://images.unsplash.com/photo-1633110187937-6e3b2f36dfca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
    alt: "interior designer dubai",
  },
];
function Commercial() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["Commercial Design Interior"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Best Commercial Design Interior | Interior Designers In Dubai”"}
        desc={
          "Premier commercial design interior services in Dubai. Experts in interior fit outs and commercial decorating. Contact us today at +971551574148."
        }
      />
      <div className="commercial__banner">
        <div className="commercial__header">
          <ReactTypingEffectDemo />{" "}
        </div>
      </div>
      <div className="commercial">
        <Heading text2={"Turkey Interior Design Services Dubai"} />

        <p>
          As a leading commercial interior design company in Dubai, Crystal Home
          Interior offers expert services in creating functional and visually
          appealing commercial spaces. We pride ourselves on transparency,
          integrity, and delivering designs that exceed expectations.
        </p>

        <h3>The manner observed is as follows:</h3>

        <div className="commercial__lists">
          <ul>
            <li>
              <span className="commercial__lists span">
                Planning & Design Development:{" "}
              </span>
              Tailored solutions to match your business needs and brand
              identity.
            </li>
            <li>
              <span className="commercial__lists span">
                Complete Space Solutions:{" "}
              </span>
              From space planning to furnish selection and final design, we
              cover all essential aspects.
            </li>
            <li>
              <span className="commercial__lists span">
                Accurate Cost & Time Estimates:{" "}
              </span>
              Clear, detailed estimations to help you manage your project
              efficiently.
            </li>
            <li>
              <span className="commercial__lists span">
                Seamless Execution:{" "}
              </span>
              Our experienced team manages every phase of the project, ensuring
              quality and satisfaction.
            </li>
          </ul>

          <h3>Comprehensive Commercial Interior Design Services:</h3>

          <h4>Interiors</h4>

          <ul>
            <li>
              <span className="commercial__lists span">
                Interior Finishes:{" "}
              </span>
              High-quality finishes that reflect your brand
            </li>
            <li>
              <span className="commercial__lists span">
                <a href="/our-services/joinery-works">Woodwork & Joinery</a>:{" "}
              </span>
              Custom-crafted woodwork tailored to your space.
            </li>
            <li>
              <span className="commercial__lists span">
                Civil and Interior Work:{" "}
              </span>
              Full-service solutions for your design needs.
            </li>
            <li>
              <span className="commercial__lists span">Gypsum Walls: </span>
              Stylish and functional wall designs to enhance interiors.{" "}
            </li>
          </ul>

          <h3>MEP (Mechanical, Electrical, and Plumbing):</h3>

          <ul>
            <li>
              <span className="commercial__lists span">
                Fireplace Combating:{" "}
              </span>
              Advanced solutions for stylish, safe fireplace installations..{" "}
            </li>
            <li>
              <span className="commercial__lists span">Plumbing: </span>
              Efficient systems designed for optimal functionality.
            </li>
            <li>
              <span className="commercial__lists span">Plumbing: </span>
              Efficient systems designed for optimal functionality.
            </li>
            <li>
              <span className="commercial__lists span">Air Conditioning: </span>
              High-performance systems for climate control.{" "}
            </li>
            <li>
              <span className="commercial__lists span">Electrical: </span>
              Safe, reliable electrical setups for commercial spaces.{" "}
            </li>
          </ul>
        </div>

        <div className="commercial__gallery">
          <h3> Why Choose Us?</h3>
          <p style={{ marginBottom: 10 }}>
            At <a href="/">Crystal Home,</a> we are more than just a commercial
            interior design company in Dubai; we are your partners in creating
            engaging, efficient, and aesthetically pleasing spaces. Whether it's
            a complete redesign or a targeted upgrade, our commercial interior
            decorator team ensures your project is handled with care and
            expertise, bringing your vision to life.
          </p>

          {pics.map((pic, index) => {
            return (
              <div className="pic__gallery" key={index}>
                <div className="pic">
                  <img src={pic.url} alt={pic.alt} />
                </div>

                <div className="pic__logo">
                  <img src={Logo} alt="logo" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Commercial;
