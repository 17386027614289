import React, { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import "../single/Single.css";
import { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

function RetailInterior() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);
  const excerpts = [
    "https://images.unsplash.com/photo-1616046229478-9901c5536a45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1615875474908-f403116f5287?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1615874694520-474822394e73?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1600607686527-6fb886090705?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1616594039964-ae9021a400a0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1616486338812-3dadae4b4ace?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1616593918824-4fef16054381?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h",

    "https://images.unsplash.com/photo-1583847268964-b28dc8f51f92?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1600054648630-e10e710825f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1615875605825-5eb9bb5d52ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjJ8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
  ];
  return (
    <>
      <Banner
        img={
          "https://images.unsplash.com/photo-1600607686527-6fb886090705?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
        }
      />
      <div className="single">
        <div className="excerpts">
          <Swiper
            spaceBetween={4}
            slidesPerView={mobile ? 1 : 2}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: false }}
            modules={[Autoplay]}
            className="single__swiper"
          >
            {excerpts.map((client, index) => {
              return (
                <div key={index} className="excerpts__content">
                  <SwiperSlide>
                    <img src={client} alt="" />
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default RetailInterior;
