import React from "react";
import "./Text.css";

function Text({ text, color }) {
  return (
    <div className="text" style={{ color: color }}>
      {text}
    </div>
  );
}

export default Text;
