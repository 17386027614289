import React from "react";
import { Logo } from "../../imports/Images";
import "./Loader.css";

function Loader() {
  return (
    <div className="loader">
      <div className="loader__dots">
        <div className="dot" style={{ animationDelay: "calc(0.1s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(0.2s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(0.3s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(0.4s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(0.5s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(0.6s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(0.7s)" }}></div>
      </div>

      <div>
        <img src={Logo} alt="" />
      </div>

      <div className="loader__dots">
        <div className="dot" style={{ animationDelay: "calc(-0.1s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(-0.2s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(-0.3s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(-0.4s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(-0.5s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(-0.6s)" }}></div>
        <div className="dot" style={{ animationDelay: "calc(-0.7s)" }}></div>
      </div>
    </div>
  );
}

export default Loader;
