import React, { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import "../single/Single.css";
import { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

function BeautyInterior() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);
  const excerpts = [
    "https://images.unsplash.com/photo-1580491934306-acf5f6886d90?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1615875474908-f403116f5287?ihttps://images.unsplash.com/photo-1498843053639-170ff2122f35?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1547887538-047f814bfb64?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzB8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1499313843378-eebdb187f629?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzR8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1581182815808-b6eb627a8798?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1487412912498-0447578fcca8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzV8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1509967419530-da38b4704bc6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzh8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1525373612132-b3e820b87cea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzZ8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1600054648630-e10e710825f6?ihttps://images.unsplash.com/photo-1540554827922-41d1fe9a7a40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDF8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1600484732755-b8246d320dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDd8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
  ];
  return (
    <>
      <Banner
        img={
          "https://images.unsplash.com/photo-1600484732755-b8246d320dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDd8fGJlYXV0eXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60"
        }
      />
      <div className="single">
        <div className="excerpts">
          <Swiper
            spaceBetween={4}
            slidesPerView={mobile ? 1 : 2}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: false }}
            modules={[Autoplay]}
            className="single__swiper"
          >
            {excerpts.map((client, index) => {
              return (
                <div key={index} className="excerpts__content">
                  <SwiperSlide>
                    <img src={client} alt="" />
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default BeautyInterior;
