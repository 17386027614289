import React from "react";
import "./Button.css";

function Button({ border, color, text, className }) {
  return (
    <div
      className={className ? className : "button"}
      style={{ color: color, border: border }}
    >
      <p>
        <span>{text}</span>
      </p>
    </div>
  );
}

export default Button;
