import React from "react";
import { ArrowForwardIcon } from "../../imports/Icons";
import "./Offer.css";

function Offer({ img, title, link, desc, alt }) {
  return (
    <div className="offer">
      <div className="offer__contents">
        <img src={img} alt={alt} />

        <div className="offer__contents__details">
          <h3> {title}</h3>

          <a href={link}>
            <p>Learn more</p>
            <span>
              <ArrowForwardIcon />
            </span>
          </a>
        </div>

        <div className="hover__contents">
          <h3> {title}</h3>
          <p>{desc}</p>
        </div>
      </div>
    </div>
  );
}

export default Offer;
