import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import { Logo } from "../../../imports/Images";
import "./Woodwork.css";
import Head from "../../../components/meta/Head";

function Woodwork() {
  const pics = [
    {
      url: "https://images.unsplash.com/photo-1600607686527-6fb886090705?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

      alt: "woodworking machinery",
    },

    {
      url: "https://images.unsplash.com/photo-1615873968403-89e068629265?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aW50ZXJpb3JzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

      alt: "best woodworking tools",
    },

    {
      url: "https://images.unsplash.com/photo-1616486338812-3dadae4b4ace?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",

      alt: "woodworking business plan",
    },

    {
      url: "https://images.unsplash.com/photo-1594873604892-b599f847e859?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "joinery companies in dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1600054648630-e10e710825f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "joinery works",
    },

    {
      url: "https://images.unsplash.com/photo-1628744876497-eb30460be9f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "joinery dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1615875605825-5eb9bb5d52ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjJ8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "joinery dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1597218868981-1b68e15f0065?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "joinery dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1627903366077-245881c54748?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "joinery dubai",
    },

    {
      url: "https://images.unsplash.com/photo-1633110187937-6e3b2f36dfca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fGludGVyaW9yc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
      alt: "joinery dubai",
    },
  ];
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["woodwork / joinery"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Joinery Works in Dubai | Expert Woodwork & Joinery Services"}
        desc={
          "“Discover top-notch joinery works with Crystal Home Interior. Our expert team specializes in woodworking and joinery services in Dubai."
        }
      />
      <div className="woodwork__banner">
        <div className="woodwork__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="woodwork">
        <Heading text={"Joinery Works"} />

        <div className="woodwork__content">
          Joinery is a specialized aspect of woodworking that involves
          skillfully joining pieces of timber or lumber to create intricate and
          functional items. The characteristics of wooden joints—such as
          strength, flexibility, and appearance—are influenced by the materials
          used and the intended purpose of the joint. Different joinery
          techniques are employed to meet various requirements, ensuring that
          each project is tailored to its specific context. For instance, the
          joinery used in constructing a house may differ from that used in a
          commercial space, although some principles remain consistent. At
          Falcon Interior, we pride ourselves on being among the{" "}
          <span className="bold__span">best joinery companies in Dubai. </span>
          Our unique styles and exquisite craftsmanship set us apart in the
          industry. Our team of skilled joiners is dedicated to delivering
          high-quality artisan services that align with the priorities and
          interests of our clients.
        </div>

        <Heading text={"Crafting Beautiful Designs"} />

        <p>
          Joinery is not just about functionality; it also creates stunning
          designs and patterns that enhance the interior and exterior of any
          constructed space. We utilize a variety of wood and wood-processed
          materials to frame attractive designs, ensuring that every piece we
          create is both beautiful and practical. The artistic expertise of our
          joiners allows us to produce unique designs on frames, panels, and
          other elements that elevate your space.
        </p>

        <div className="commercial">
          {/* <Heading text={"Comprehensive Turnkey Interior Design Services"} /> */}

          <div className="main__retail__text heading">
            <h3>
              Comprehensive Turnkey{" "}
              <a
                href="/our-services/interior-design-services-company"
                style={{ color: "#cd9a61" }}
              >
                Interior Design Services
              </a>
            </h3>
          </div>

          <p>
            As one of the leading providers of turnkey interior design services
            in the UAE, we believe in complete transparency and integrity
            throughout the process. Our approach includes:
          </p>

          <div className="commercial__lists">
            <ul>
              <div className="list__spacing">
                <li className="bold__list"> Development and Design:</li>

                <span>
                  We start with a thorough understanding of your vision and
                  requirements.
                </span>
              </div>

              <div className="list__spacing">
                <li className="bold__list">
                  {" "}
                  Space Planning and Furniture Selection:
                </li>

                <span>
                  Our team carefully considers every aspect of your space to
                  optimize functionality and aesthetics.
                </span>
              </div>

              <div className="list__spacing">
                <li className="bold__list"> Cost and Time Estimates:</li>

                <span>
                  We provide clear estimates to ensure your project stays on
                  track and within budget.
                </span>
              </div>
            </ul>

            <h3>
              Our turnkey interior design services encompass a wide range of
              offerings, including:{" "}
            </h3>

            <ul>
              <li>Interiors</li>
              <li>Interior Finishes</li>
              <li>Woodwork & Joinery</li>
              <li>Civil and Interior Work</li>
              <li>Gypsum Walls</li>
              <li>MEP (Mechanical, Electrical, and Plumbing)</li>
              <li>Fire Fighting Systems</li>
              <li>Plumbing</li>
              <li>Air Conditioning</li>
              <li>Electrical Work</li>
            </ul>
          </div>
        </div>

        <Heading text={"Your Partner in Woodworking Excellence"} />

        <p>
          At Falcon Interior, we are committed to delivering exceptional{" "}
          <span className="bold__span">joinery works</span> that meet the
          highest standards of quality and craftsmanship. Our expertise in
          <span className="bold__span"> woodworking machinery</span> and the use
          of the best <span className="bold__span">woodworking tools</span>{" "}
          ensures that every project is executed with precision and care.
          Whether you are looking to enhance your residential space or create a
          stunning commercial environment, our team is here to help you realize
          your vision.Let us transform your interiors with our expert joinery
          services. Contact Falcon Interior today to discuss your project and
          discover how we can bring your ideas to life!
        </p>

        <br />

        <div className="commercial__gallery">
          {pics.map((pic, index) => {
            return (
              <div className="pic__gallery" key={index}>
                <div className="pic">
                  <img src={pic.url} alt={pic.alt} />
                </div>

                <div className="pic__logo">
                  <img src={Logo} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Woodwork;
