import React from "react";
import Banner from "../../components/banner/Banner";
import Heading from "../../components/text/Heading";
import { Ceo } from "../../imports/Images";
import "./About.css";
import Head from "../../components/meta/Head";

function About() {
  return (
    <div className="about">
      <Head
        title={"Explore More About Crystal Home | Interior Design Services"}
        desc={
          "Looking to renovate your space with skilled technicians? Explore about Crystal Home, leading decoration company of Dubai"
        }
      />
      <Banner img={Ceo} />

      <div className="about__contents">
        <Heading text={"Who Are Crystal Home?"} />

        <p>
          Our team of passionate specialists, committed to providing exceptional
          working environments.
        </p>

        <p>
          With a heritage of 8 year of successful projects and the team use our
          combined experience to analyses and understand your business culture.
        </p>

        <Heading text={"What We Offer?"} />

        <p>
          We provide our clients with outstanding services from the stage of
          Conceptualization to Completion of the project. It is important to
          understand that there are several steps that need to be undertaken in
          order to successfully complete a given project. We not only ensure
          taking care of your designs and fit out but everything that needs to
          be fulfilled along with it. Crystal home Interior Decoration is one of
          the leading Interior Design & Fit-out Contracting Company in Dubai
          with an exceptional reputation for providing innovative, quality and
          extraordinary designs and services. We cater all interior design and
          fit-out solutions as a commercial, Residential, Hospitality
          Restaurant, Office, Joinery, Beauty Salon and Spa, Retail and Health
          clinic.
        </p>

        <Heading text={"Our History?"} />

        <p>
          In 2015, Mr. MALIK began his venture into decorating the interior of
          Residential Apartments and Villas. This is how Crystal Home was born.
          Word quickly spread about our great service and customer-focused
          experience. This led to consistent growth and, fast forward to 2022,
          we have built numerous meaningful and strong relationships with
          businesses across the UAE, becoming one of the region’s largest
          designs and fit-out firms.
        </p>
      </div>
    </div>
  );
}

export default About;
