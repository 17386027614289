import {
  Project1,
  Project2,
  Project3,
  Project4,
  Project5,
  Project6,
  Project7,
  Project8,
  Project9,
} from "../../imports/Images";

const Data = [
  {
    img: Project1,
    title: "Interior Design",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/interior",
  },

  {
    img: Project2,
    title: "Bathroom Interior",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/bathroom",
  },

  {
    img: Project3,
    title: "Flower Design",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/flower",
  },

  {
    img: Project4,
    title: "Retail Fit Out",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/retail",
  },

  {
    img: Project5,
    title: "Woodwork",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/woodwork",
  },

  {
    img: Project6,
    title: "Medical Centre And Clinics",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/medical",
  },

  {
    img: Project7,
    title: "Beauty Salon",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/beauty",
  },

  {
    img: Project8,
    title: "Full House Decoration",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/fullhouse",
  },

  {
    img: Project9,
    title: "Office Fit Out",
    desc: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISICING ELIT.",
    url: "/projects/office",
  },
];

export default Data;
