import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Authority.css";
import Head from "../../../components/meta/Head";

function Authority() {
  const pics = [
    "https://plus.unsplash.com/premium_photo-1661563835979-0257f71e34d9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1512061942530-e6a4e9a5cf27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1516788875874-c5912cae7b43?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1596701062351-8c2c14d1fdd0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8aG9zcGl0YWxpdHl8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574578831673-0570b75bf2ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1519624133901-3420156d01b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574919369977-5ecb109b5a74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1589659964144-cd22a01ec20e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1562932054-d476e39c4c45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",

    "https://images.unsplash.com/photo-1574919630248-d5fad3f17f51?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fGhvc3BpdGFsaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
  ];
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["authority approval"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Authority Approval Interior Services in Dubai | Best Solutions"}
        desc={
          "“Ensure your interior fit-out project is compliant with government regulations with our authority approval services in Dubai. We handle, NOCs & Permits"
        }
      />
      <div className="authority__banner">
        <div className="authority__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="authority">
        <Heading text2={"authority Approvals"} />

        <div className="authority__content">
          <p>
            Obtaining the necessary authority approval is a crucial step before
            commencing any interior fit-out project in commercial, residential,
            or hospitality spaces. Ensuring compliance with government
            regulations not only facilitates a smoother project execution but
            also safeguards against potential legal issues down the line.At
            Falcon Interior, we provide comprehensive{" "}
            <span className="bold__span">authority approval </span>
            solutions tailored to meet the needs of your specific project. Our
            expert team is well-versed in navigating the complexities of the
            approval process, ensuring that all requirements are met efficiently
            and effectively.
          </p>
        </div>

        <p>
          Our authority approval services include a full suite of offerings to
          streamline the compliance process:
        </p>

        <div className="residential">
          <div className="residential__content">
            <Heading text={"Comprehensive Authorization Solutions"} />

            <div className="list__spacing">
              <li className="bold__list"> Drawing Submission:</li>

              <span>
                We assist in preparing and submitting detailed architectural and
                engineering drawings for review and approval by relevant
                authorities.
              </span>
            </div>

            <div className="list__spacing">
              <li className="bold__list">Documentation:</li>

              <span>
                Our team ensures that all necessary documentation is
                meticulously prepared and submitted, minimizing delays in the
                approval process.
              </span>
            </div>
            <div className="list__spacing">
              <li className="bold__list">No-Objection Certificates (NOC):</li>

              <span>
                We facilitate the acquisition of government no-objection
                certificates, which are essential for proceeding with your
                fit-out project.
              </span>
            </div>
            <div className="list__spacing">
              <li className="bold__list">Permits and Inspections:</li>

              <span>
                We manage the application for various permits and coordinate
                inspections to ensure that your project adheres to all
                regulations and standards.
              </span>
            </div>
            <div className="list__spacing">
              <li className="bold__list">Completion Certifications:</li>

              <span>
                Upon project completion, we assist in obtaining the necessary
                certifications to confirm compliance with all authority
                requirements.
              </span>
            </div>
          </div>
        </div>

        <Heading text={""} />

        <div className="authority__content">
          <p style={{ marginBottom: 10 }}>
            With years of experience in the industry, Falcon Interior has the
            expertise to address your engineering needs for authority approvals.
            <a href="/our-team"> Our dedicated team</a> understands the
            importance of timely approvals and works diligently to expedite the
            process, allowing you to focus on your core business activities.
            Partner with us for your authority approval needs and ensure that
            your interior fit-out project is compliant with all government
            regulations. Contact us today to learn more about our services and
            how we can assist you in achieving a seamless approval process!
          </p>
        </div>
      </div>
    </>
  );
}

export default Authority;
