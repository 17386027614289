import "./Single.css";
import React, { useEffect, useState, Suspense, lazy } from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Dynamically import the Banner component

import {
  Offer3,
  Project1,
  Project2,
  Project3,
  Project4,
  Project5,
  Project6,
  Project7,
  Project8,
  Project9,
} from "../../imports/Images";
const Banner = lazy(() => import("../../components/banner/Banner"));

function Single() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 550) {
      setMobile(true);
    }
  }, [mobile]);

  const excerpts = [
    Project1,
    Project2,
    Project3,
    Project4,
    Project5,
    Project6,
    Project7,
    Project8,
    Project9,
  ];

  return (
    <>
      <Suspense fallback={<div>Loading Banner...</div>}>
        <Banner img={Offer3} />
      </Suspense>
      <div className="single">
        <div className="excerpts">
          <Swiper
            spaceBetween={4}
            slidesPerView={mobile ? 1 : 2}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            pagination={{ clickable: false }}
            modules={[Autoplay]}
            className="single__swiper"
          >
            {excerpts.map((client, index) => (
              <div key={index} className="excerpts__content">
                <SwiperSlide>
                  <img src={client} alt="" />
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Single;
