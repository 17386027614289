import React from "react";
import "./Heading.css";

function Heading({ text, subheading, others, text2 }) {
  return (
    <div className="heading">
      <h3>{text}</h3>
      <h2>{text2}</h2>

      <p>{subheading}</p>
      <h4>{others}</h4>
    </div>
  );
}

export default Heading;
