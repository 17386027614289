import React from "react";
import Heading from "../../components/text/Heading";
import { ArrowForwardIcon } from "../../imports/Icons";
import "./Projects.css";
import Data from "./data";
import ReactTypingEffect from "react-typing-effect";
import Head from "../../components/meta/Head";

function Projects() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["projects"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={
          "Explore Crystal Home Projects | Modern Interior Design Solutions"
        }
        desc={
          "Discover our projects at Falcon Interior, showcasing innovative and transformative interior design solutions across various sectors"
        }
      />
      <div className="projects__banner">
        <div className="projects__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <p className="projects__heading">
        <Heading text={"our projects"} />
      </p>
      <div className="projects__main">
        {Data.map((project, index) => {
          return (
            <div key={index} className="projects page">
              <div className="projects__contents">
                <img src={project.img} alt="" />
                <div className="projects__hover__contents">
                  <div>
                    <p id="beauty">beauty salon & spa</p>
                    <h3> {project.title}</h3>
                    {/* <p id="desc">{project.desc}</p> */}

                    <a href={project.url}>
                      <p>Learn more</p>
                      <span>
                        <ArrowForwardIcon />
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              <h3 className="main__text">{project.bottomText}</h3>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Projects;
