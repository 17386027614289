import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import "./Office.css";
import Head from "../../../components/meta/Head";

function Office() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["Office Interior Design"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Office Interior Design in Dubai | Crystal Home Interiors”"}
        desc={
          "“Explore expert office interior design services in Dubai. Our team specializes in creative and cost-effective office fit out solutions that enhance your workplace.”"
        }
      />
      <div className="office__banner">
        <div className="office__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="office">
        <Heading text2={"Office Fit Out Services "} />

        <div className="office__content">
          <p>
            Creating a welcoming and professional office environment is
            essential for making a lasting impression on clients and employees
            alike. <span className="bold__span">Office decorum</span> plays a
            significant role in reflecting your business identity, and achieving
            this requires the expertise of seasoned professionals. At Falcon
            Interior, one of the leading{" "}
            <span className="bold__span">office fit out</span> companies in
            Dubai, we take pride in our ability to transform a wide range of
            office spaces across the UAE.Our talented team of interior designers
            combines artistic vision, cutting-edge technology, and high-quality
            materials to craft spaces that connect, captivate, and inspire
            everyone who enters. With a comprehensive portfolio of{" "}
            <span className="bold__span">office interior design</span> services,
            we are dedicated to delivering solutions tailored to your unique
            needs
          </p>

          <h3>Bring Life to Your Workplace</h3>

          <p>
            Falcon Interior collaborates with a select group of skilled experts
            who excel in creating upscale office environments. Whether you need
            custom-made furniture or the latest trends from the global market,
            our team is equipped to work magic in any space.Let us help you
            elevate your office with innovative designs that enhance
            productivity and foster a vibrant work culture. Experience the
            difference with Falcon Interior, your go-to resource for{" "}
            <span className="bold__span">
              <a href="/">interior design office near me</a>
            </span>{" "}
            and <span className="bold__span">office interior design Dubai</span>
            . Transform your workspace into a place where creativity thrives and
            employees feel inspired!
          </p>
        </div>
      </div>
    </>
  );
}

export default Office;
