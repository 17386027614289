import React from "react";
import { Ceo, Employees } from "../../imports/Images";
import "./Team.css";
import ReactTypingEffect from "react-typing-effect";
import { Helmet } from "react-helmet";
import Head from "../../components/meta/Head";

function Team() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        {/* <h1>Meet the Experts Behind Crystal Home Interiors</h1> */}
        <ReactTypingEffect
          text={["Meet the Experts Behind Crystal Home Interiors"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Meet the Experts Behind Crystal Home Interiors in Dubai"}
        desc={
          "Meet the skilled team at Crystal Home Interiors in Dubai, led by CEO Mr.Malik, and dedicated to transforming your spaces with exceptional interior design."
        }
      />
      <div className="team__banner">
        <div className="team__header">
          <ReactTypingEffectDemo />
        </div>
      </div>

      <div className="licence__ceo">
        <img src={Ceo} alt="CEO Crystal Home" />
        <h1 style={{ marginTop: 20 }}>
          Meet Mr. Malik - CEO of Crystal Home Interiors
        </h1>
        <p>
          Mr. Malik leads our team with over 15 years of experience in interior
          design and fit-out projects. His vision and dedication drive Crystal
          Home Interiors to deliver exceptional designs that resonate with
          elegance and functionality.
        </p>
      </div>
      <div className="team">
        <img src={Employees} alt="" />
      </div>
    </>
  );
}

export default Team;
