import React from "react";
import {
  CallIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  LocationOnIcon,
  MailIcon,
  TwitterIcon,
} from "../../imports/Icons";
import { Logo } from "../../imports/Images";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer__first">
          <a href="/">
            <img src={Logo} alt="" />
          </a>
        </div>
        <div className="footer__second">
          <h1>INFORMATION</h1>

          <ul>
            <li>
              <a href="/">home</a>
            </li>

            <li>
              <a href="/about">about us</a>
            </li>

            <li>
              <a href="/our-services">our services</a>
            </li>

            <li>
              <a href="/projects">our projects</a>
            </li>

            <li>
              <a href="/clients">our clients</a>
            </li>
          </ul>
        </div>
        <div className="footer__third">
          <h1>OUR SERVICES</h1>

          <ul>
            <li>
              <a href="/services/commercial">commercial</a>
            </li>

            <li>
              <a href="/services/residential"> residential</a>
            </li>

            <li>
              <a href="/services/hospitality">hospitality</a>
            </li>

            <li>
              <a href="/services/restaurant">restaurant</a>
            </li>

            <li>
              <a href="/services/office">office fit out</a>
            </li>
          </ul>
        </div>
        <div className="footer__fourth">
          <ul>
            <li>
              <a href="/services/beauty">beauty salon & spa</a>
            </li>

            <li>
              <a href="/services/retail">retail fit out</a>
            </li>

            <li>
              <a href="/services/woodwork">woodwork / joinery</a>
            </li>

            <li>
              <a href="/services/medical">medical centers and clinics</a>
            </li>
          </ul>
        </div>
        <div className="footer__fifth">
          <p>
            <CallIcon />
            <a href="tel:+2345667899">+971551574148</a>
          </p>

          <p>
            <MailIcon />
            <a href="mailto:info.crystalhome512@gmail.com">
              {" "}
              info.crystalhome512@gmail.com
            </a>
          </p>

          <p>
            <LocationOnIcon />
            <span>
              Office 620 B, Burj Kalifa Street, WareHouse No : S 28, Ras AI Khor
            </span>
          </p>

          <div className="nav__contact__icons__others">
            <a
              href="https://www.facebook.com/crystal.home.5477"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>

            <a
              href="https://www.instagram.com/info.crystalhome512/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>

            <a
              href="https://www.linkedin.com/in/crystal-home-technical-services-718542258?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B73uOwchFTfyCXTHdMO5Y9Q%3D%3D"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>

            <a
              href="https://twitter.com/CrystalHomeTech"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
      </div>

      <div className="dev__by">
        <a href="https://apnadevs.com" target="_blank" rel="noreferrer">
          {" "}
          Developed By ApnaDevs{" "}
        </a>
      </div>
    </>
  );
}

export default Footer;
