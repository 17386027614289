import React from "react";
import "./Clients.css";

function Clients({ icon, number, name }) {
  return (
    <div className="clients">
      <div className="clients__icon">{icon}</div>

      <div className="clients__text">
        <p id="number">{number} +</p>

        <p id="text">{name}</p>
      </div>
    </div>
  );
}

export default Clients;
