import React from "react";
import ReactTypingEffect from "react-typing-effect";
import Heading from "../../../components/text/Heading";
import { Logo } from "../../../imports/Images";
import "./Interior.css";
import Head from "../../../components/meta/Head";
import { pics } from "../commercial/Commercial";

function Interior() {
  const ReactTypingEffectDemo = () => {
    return (
      <>
        {/* <ReactTypingEffect text={[".", "!"]} /> */}

        <br />

        <ReactTypingEffect
          text={["Top Interior Design Company In Dubai"]}
          cursorRenderer={(cursor) => <h5>{cursor}</h5>}
          displayTextRenderer={(text, i) => {
            return (
              <h1>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  return (
                    <span
                      key={key}
                      style={i % 2 === 0 ? { color: "white" } : {}}
                    >
                      {char}
                    </span>
                  );
                })}
              </h1>
            );
          }}
        />
      </>
    );
  };

  return (
    <>
      <Head
        title={"Best Interior Design Services Company in Dubai - Crystal Home"}
        desc={
          "Looking for an interior design services company in Dubai? Crystal Home offers expert interior design services. Transform your space with us!"
        }
      />
      <div className="interior__banner">
        <div className="interior__header">
          <ReactTypingEffectDemo />
        </div>
      </div>
      <div className="interior">
        <Heading
          text2={"Excellent Interior Design Services - Crystal Home Interiors"}
        />

        <div className="interior__content">
          <p>
            Are you looking for a reliable{" "}
            <span
              style={{ fontWeight: 800, color: "blue", cursor: "pointer" }}
              onClick={() => (window.location.href = "/")}
            >
              {" "}
              interior design company
            </span>{" "}
            in Dubai? Crystal Home is your go-to solution for transforming
            residential and commercial spaces into functional, aesthetically
            pleasing, and luxurious environments. Our expertise in interior
            design spans across various sectors including homes, offices,
            salons, clinics, schools, and more. We cater to clients who want to
            renovate or revamp their spaces with innovative design solutions.
          </p>

          <h3>Why Choose Crystal Home?</h3>

          <p>
            As a top{" "}
            <span style={{ fontWeight: 800 }}>
              interior design company in Dubai,
            </span>{" "}
            Crystal Home is dedicated to delivering excellence in every project.
            Here's what sets us apart:
          </p>

          <ul>
            <li>
              <span className="commercial__lists span">
                Extensive Experience:{" "}
              </span>
              With over 6 years of experience in the industry, we have
              successfully completed projects in diverse sectors, ensuring each
              space meets the specific needs and preferences of our clients.{" "}
            </li>
            <li>
              <span className="commercial__lists span">
                Innovative and Functional Designs:{" "}
              </span>
              We combine creativity with practicality to create designs that are
              not only beautiful but also highly functional.{" "}
            </li>
            <li>
              <span className="commercial__lists span">
                Client-Centric Approach:{" "}
              </span>
              We work closely with our clients to understand their vision and
              deliver customized design solutions that exceed their
              expectations.{" "}
            </li>
          </ul>

          {/* <p>
            Whether it’s a luxury villa interior design or a beauty salon
            interior design, we deliver the finest and most creative for all our
            clients. To ensure that our clients are happy with the outcomes, we
            thoroughly choose and present our initiatives. We provide genuine,
            artistic, and unique services and transform them into masterpieces.
            We are a leading interior fitout companies in Dubai providing
            interior designing services for 6 years with topnotch quality and
            client satisfaction.
          </p> */}

          <Heading text={" Comprehensive Interior Design Services"} />

          <p>
            Crystal Home provides a wide range of interior design services
            tailored to meet the unique needs of our clients:
          </p>

          <ol>
            <li>
              <span className="commercial__lists span">
                <a href="/our-services/residential-interior-designers-dubai">
                  Residential Interior Design:
                </a>
              </span>{" "}
              <br />
              We specialize in creating luxurious and comfortable living spaces
              tailored to your style and needs.
            </li>

            <li>
              <span className="commercial__lists span">
                <a href="/our-services/commercial-design-interior">
                  Commercial Interior Design:
                </a>
              </span>{" "}
              <br />
              Enhance your business environment with our innovative design
              solutions that improve both aesthetics and functionality.
            </li>
            <li>
              <span className="commercial__lists span">
                <a href="/our-services/best-hospitality-design-firms-dubai">
                  Hospitality Design:
                </a>
              </span>{" "}
              <br />
              We design inviting and memorable interiors for hotels, resorts,
              and other hospitality venues.
            </li>
            <li>
              <span className="commercial__lists span">
                <a href="/our-services/salon-interior-design">
                  Beauty Salon & Spa Interior Design:
                </a>
              </span>{" "}
              <br />
              Our designs for salons and spas provide a relaxing atmosphere that
              reflects the essence of your brand.
            </li>
            <li>
              <span className="commercial__lists span">
                <a href="/our-services/medical-clinic-design-dubai">
                  Medical Centers and Clinics Design:
                </a>
              </span>{" "}
              <br />
              We create calming and efficient spaces for healthcare settings
              that prioritize patient comfort
            </li>
            <li>
              <span className="commercial__lists span">
                <a href="/our-services/interior-design-services-company">
                  Educational Institutions Design:
                </a>
              </span>{" "}
              <br />
              Inspire learning with our creative design solutions for schools
              and academies.
            </li>
            <li>
              <span className="commercial__lists span">
                <a href="/our-services/joinery-works">
                  Custom Joinery & Woodwork:
                </a>{" "}
              </span>{" "}
              <br />
              Elevate your interiors with our high-quality custom joinery and
              woodwork services.
            </li>
          </ol>
        </div>

        <div className="commercial">
          <Heading text={"Our Process"} />

          <p>
            Our process ensures that every project is completed with precision
            and excellence:
          </p>

          <h3>The manner observed is as follows:</h3>

          <div className="commercial__lists">
            <ul>
              <li>
                <span className="commercial__lists span">Consultation:</span>{" "}
                <br />
                We start by understanding your vision and requirements, setting
                the foundation for a successful project.
              </li>
              <li>
                <span className="commercial__lists span">
                  Concept Development:
                </span>{" "}
                <br />
                We create a unique design concept that aligns with your style
                and budget.
              </li>
              <li>
                <span className="commercial__lists span">
                  Detailed Design & Planning:
                </span>{" "}
                <br />
                We provide detailed plans, mood boards, and 3D renderings to
                give you a clear vision of the final design.
              </li>
              <li>
                <span className="commercial__lists span">
                  Material Selection:
                </span>{" "}
                <br />
                We assist you in selecting the best materials and finishes that
                complement the design.
              </li>
              <li>
                <span className="commercial__lists span">
                  Execution & Installation:
                </span>{" "}
                <br />
                Our team ensures that every element is executed to perfection,
                delivering a space that exceeds your expectations.
              </li>
            </ul>

            <h3>
              Our turnkey indoors layout services consist of the following:
            </h3>

            <h4>Interiors</h4>

            <ul>
              <li>interior Finishes</li>
              <li>Woodwork & Joinery</li>
              <li>Civil and interior work</li>
              <li>Gypsum walls</li>
            </ul>

            <h3>MEP</h3>

            <ul>
              <li>fireplace combating</li>
              <li>Plumbing</li>
              <li>air conditioning</li>
              <li>electric</li>
            </ul>
          </div>
        </div>

        <div className="commercial__gallery">
          {pics.map((pic, index) => {
            return (
              <div className="pic__gallery" key={index}>
                <div className="pic">
                  <img src={pic.url} alt={pic.alt} />
                </div>

                <div className="pic__logo">
                  <img src={Logo} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Interior;
